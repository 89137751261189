import Service from '@/services'

export default {
  data () {
    return {
      loading: false,
      saveLoading: false,
      valid: true,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      form: {
        title: null,
        categoryDictionaryId: null,
        orderingCompany: {
          id: null,
          displayId: null,
          user: {
            id: null
          },
          dateOfIssueRegistration: null
        },
        details: {
          description: null,
          dateOfOrderExecution: null,
          orderId: null,
          chatContext: null,
          documents: []
        },
        linkedCompany: {
          Id: null,
          displayId: null,
          user: {
            id: null
          }
        },
        assignedTo: null,
        state: null
      },
      categoriesIssue: [],
      usersToAssign: [],
      menu: false,
      menu2: false,
      selectedCompanyOrdering: null,
      companies: [],
      companyOrderingLoading: false,
      companyOrderingSearch: null,
      employeesOrdering: [],
      selectedLinkedCompany: null,
      companyLinkedLoading: false,
      linkedCompanies: [],
      companyLinkedSearch: null,
      employeesLinked: []
    }
  },
  computed: {
    users () {
      return this.$store.getters['users/users']
    },
    chatContextTypes () {
      return this.$store.getters.enums.chatContextTypes
    },
    issueState () {
      return this.$store.getters.enums.issueState
    }
  },
  methods: {
    getUser (userId) {
      return this.users.find(user => user.id === userId)
    },
    async selectedCompanyOrderingSearchInput (companyName) {
      if (!companyName || companyName.length < 3) {
        return
      }

      this.companyOrderingLoading = true
      try {
        const { data: companies } = await Service.get('Issue/GetCompanyByName', {
          companyName
        })
        this.companies = companies
      } catch (e) {
      } finally {
        this.companyOrderingLoading = false
      }
    },
    async selectedCompanyOrderingInput (company) {
      this.form.orderingCompany.id = company.id
      this.form.orderingCompany.displayId = company.displayId

      try {
        const { data: employees } = await Service.get('Issue/GetCompanyUsers', {
          CompanyId: company.id
        })
        this.employeesOrdering = employees
      } catch (e) {
      }
    },
    async selectedCompanyLinkedSearchInput (companyName) {
      if (!companyName || companyName.length < 3) {
        return
      }

      this.companyLinkedLoading = true
      try {
        const { data: companies } = await Service.get('Issue/GetCompanyByName', {
          companyName
        })
        this.linkedCompanies = companies
      } catch (e) {
      } finally {
        this.companyLinkedLoading = false
      }
    },
    async selectedCompanyLinkedInput (company) {
      this.form.linkedCompany.id = company.id
      this.form.linkedCompany.displayId = company.displayId

      try {
        const { data: employees } = await Service.get('Issue/GetCompanyUsers', {
          CompanyId: company.id
        })
        this.employeesLinked = employees
      } catch (e) {
      }
    },
    displayFullName (item) {
      return `${item.name} ${item.surname}`
    }
  }
}
